.orcamentos#orcamento-mod-1{

	.titles{
		font-family: 'montserratbold',sans-serif;
		color: map-get($cores, cor-primaria );
		font-size: 26px;
	}

	.text-muted{
		color: map-get($cores, font-color );
		font-size: 18px;
		font-family: 'montserratregular',sans-serif;
	}

	.md-form{
		& > label,
		.form-control{
			color: #777;
			box-shadow: none;
			outline: none;
		}

		select{
			border: 1px solid #ccc;
			outline: none;
			box-shadow: none;
			background: transparent;
			width: 540px;
			height: 45px;
			color: rgb(109, 109, 109);

			option{
				color: rgb(109, 109, 109);
			}
		}

		.form-control{
			border-radius:0;
			outline: none;

			@include media-breakpoint-up(md){
				padding: 0.35rem 1rem;
				font-size: 1.25rem;
				line-height:1.5;
			}
		}

		@include media-breakpoint-up(md){
			.select-custom ~ label{
				padding: 0.35rem 1rem;
				font-size:1rem;
			}
		}

		.btn{
			border-radius:0;
			@include media-breakpoint-up(md){
				padding: 0.35rem 1rem;
			}
		}

		.input-group{
			.form-control{
				border-right-color: transparent;
			}

			.btn{
				border-top-color: rgba(#000,0.2);
				border-right-color: rgba(#000,0.2);
				border-bottom-color: rgba(#000,0.2);
				margin:0;
			}
		}

		&.active {
			& > label,
			.form-control-lg ~ label,
			.form-control-sm ~ label{
				top:0;
				padding-top:0;
				font-size: 0.8rem;
			}
		}


	}

	form{
		display: flex;
		flex-direction: column;
		.btn__enviar{
			width: 160px;
			height: 45px;
			background: map-get($cores, cor-primaria );
			color: #fff;
			border-radius: 0;
			font-size: 16px;
			font-family: 'montserratmedium',sans-serif;
			transition: background .4s linear;
			margin-top: 20px;
			align-self: center;
			&:hover{
				background: lighten(map-get($cores, cor-primaria), 10%);
			}
		}
	}
}

@include media-breakpoint-down(md){
	.orcamentos#orcamento-mod-1{
		.md-form{
			select{
				max-width: 270px !important;
				margin-bottom: 15px;
			}
		}
	}
}
