.box__noticias{
	display: flex;
	max-width: 728px;
	.card-noticia{
		max-width: 350px;
		margin-right: 30px;

		&:hover{
			a{
				text-decoration: none;
			}

			.img__card{
				.hover{
					background: rgba(255, 255, 255, 0.453);
					background-image: url('../images/triangulo.png');
					background-position: right bottom;
					background-repeat: no-repeat;

					i{
						display: block;
					}
				}
			}
		}

		.img__card{
			width: 100%;
			height: auto;
			position: relative;
			.hover{
				position: absolute;
				top: 0;
				left:0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
				background: transparent;
				transition: background .4s linear;
				background-image: none;
				background-repeat: no-repeat;

				i{
					display: none;
					margin-bottom: 20px;
					margin-right: 20px;
					color: #fff;
				}
			}
		}

		.content__noticia{
			margin-top: 15px;

			h2{
				color: map-get($cores, cor-primaria );
				font-size: 18px;
				font-family: 'montserratextrabold';
			}

			.info__data{
				display: flex;
				align-items: center;
				padding: 5px 0;
				border-top: 1px solid #dbdbdb;
				border-bottom: 1px solid #dbdbdb;

				i{
					margin-right: 15px;
					margin-bottom: 2px;
					color: map-get($cores, font-color );
				}

				.data{
					color: map-get($cores, font-color );
					font-size: 13px;
					font-family: 'montserratmedium',sans-serif;
				}
			}

			.descricao{
				color: map-get($cores, font-color );
				font-size: 14px;
				font-family: 'montserratmedium', sans-serif;
				margin-top: 8px;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				  -webkit-box-orient: vertical;
			}
		}
	}

}


/*********************************************************************
						MOBILE/TABLET
***********************************************************************/
@include media-breakpoint-down(md){
	.box__noticias{
		display: flex;
		width: auto;
		flex-direction: column;
		align-items: center;

		.card-noticia{
			margin-bottom: 100px;
		}
	}
}
