[data-animation]{
    opacity: 0;
    transition: .7s linear;
}

[data-animation="bottom"]{
    transform: translate3d(0, 100px, 0);
}
[data-animation="left"]{
	transform: translate3d(100px, 0, 0);
}

[data-animation="right"]{
	transform: translate3d(-100px, 0, 0);
}

[data-animation].animate{
    opacity: 1;
    transform:  translate3d(0px,0px,0px);
}
