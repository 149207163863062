.contato{
    .title{
        color: #fff;
        text-transform: uppercase;
        font-family: 'montserratextrabold',sans-serif;
        font-size: 14px;
        margin-bottom: 35px;
        margin-top: 60px;
    }

    .box__contato-endereco{
        .icone{
            display: flex;
            color: #959595;
            align-items: center;

            P{
                margin-bottom: 0;
                margin-left: 10px;
                font-family: 'montserratextrabold',sans-serif;
            }
        }

        a{
            color: #959595;
            font-family: 'montserratmedium',sans-serif;
            font-size: 12px;
            margin-left: 25px;
        }

        .ml{
            margin-left: 0;
        }

        p{
            color: #959595;
            font-family: 'montserratmedium',sans-serif;
            font-size: 13px;
            margin-left: 25px;
        }

    }
}

@include media-breakpoint-down(lg){
	.contato{
		.box__contato-endereco{
			display: flex;
			flex-direction: column;
			a{
				margin-left: 0;
			}
		}
	}
}
