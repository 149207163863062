#produtos-mod-2{
	color: map-get($cores, font-color );

	.subtitle-produtos{
		font-size: 24px;
		color: map-get($cores, cor-primaria );
		font-family: 'montserratbold',sans-serif;

	}

	.text-muted{
		color: map-get($cores, font-color );
		font-size: 16px;
		font-family: 'montserratregular',sans-serif;
	}
	.row-produtos{
		& > *{
			display: flex;
		}
	}

	.produto{
		width: 100%;
		max-width: 350px;

		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;

		.foto{
			margin-bottom: 0;
		}

		.nome{
			font-size: 18px;
			font-family: 'montserratbold',sans-serif;
			text-align: center;
			padding:10px;
			color: map-get($cores, cor-primaria );
		}

		.descricao{
			// flex-grow: 1;
			color: map-get($cores, font-color );
			padding-bottom:10px;
			font-size: 16px;
			font-family: 'montserratregular',sans-serif;
		}

		.btn{
			$bg: #1f1a17;
			border-radius: 0;
			background: $bg;
			color: #FFF;
			font-family: 'montserratmedium',sans-serif;

			&:hover{
				background: lighten($bg, 10%);
				color:#FFF;
			}
		}
	}

	.paginacao{
			justify-content: center;
			margin-bottom: 30px;
		& > *{
			background: transparent;
			color: map-get($cores, cor-primaria );
			display: inline-block;
			padding: .3em .6em;
			border: 2px solid map-get($cores, cor-primaria );
			font-family: 'montserratmedium',sans-serif;


			&.active{
				background:map-get($cores, cor-primaria );
				color: #fff;

			}


		}

		a:hover{
			background:map-get($cores, cor-primaria );
			color: #fff;
			text-decoration: none;
		}
	}

	.paginacao>*+* {
		margin-left: .3em;
	}

	#carousel{
		max-width: 460px;
		margin-left:auto;
		margin-right:auto;

		figure{
			margin-bottom:0;
		}

		.controls{
			$bg: #1f1a17;
			position:absolute;
			bottom:0;
			right:0;
			background: $bg;
			display: flex;
			font-size: 20px;

			&:before{
				content:'';
				width:25px;
				height:100%;
				position:absolute;
				right:calc(100% - 4px);
				top:0;
				background: $bg;
				clip-path: polygon(0 100%, 20px 0, 100% 0, 100% 100%);
			}

			a{
				display: block;
				padding:10px;
				color: #FFF;

				&:hover{
					color: map-get($cores, font-color );
					background: rgba(#000,.2);
					text-decoration: none;
				}
			}
		}
	}

	.fas{
		color: map-get($cores, cor-primaria );
	}
}
