.contato-page#contato2{
	padding-bottom: 80px;


	#mapa-contato{
		padding-bottom: percentage(450/1920);
		min-height: 200px;
	}

	.pdt-40{
		padding-top: 40px;
	}

	h2{
		border-bottom: 1px solid currentcolor;
		margin-bottom: 30px;
		color: map-get($cores, cor-primaria );
		font-family: 'montserratmedium', sans-serif;

		&:after{
			content:'';
			display: block;
			width:60px;
			height: 2px;
			background: map-get($cores, cor-primaria );
			margin-top:10px;
		}
	}

	.fa-headset{
		font-size: 25px;
		margin-right: 20px;
	}

	h3{
		font-size: 16px;
		font-family: 'montserratbold', sans-serif;
		color: map-get($cores, cor-primaria );
	}

	h4{
		font-size: 16px;
		font-family: 'montserratbold', sans-serif;
		color: map-get($cores, cor-primaria );
	}

	p{
		font-size: 14px;
		font-family: 'montserratregular', sans-serif;
	}

	.info-block{
		color: #777;
	}

	.select-custom{
		.output{
			position: relative;
			padding-right: calc(1em + 1.5rem);

			&:before{
				content:'';
				display: block;
				position: absolute;
				border-left:0.5rem solid transparent;
				border-right:0.5rem solid transparent;
				border-top:0.5rem solid currentcolor;
				right:0.75rem;
				top:50%;
				transform: translateY(-50%);
			}
		}
	}

	select{
		box-shadow: none;
		outline: none;
		width: 270px;
		height: 37px;
		border: 1px solid #ccc;
		background: transparent;
		color: rgb(109, 109, 109);
		margin-bottom: 15px;

		option{
			color: rgb(109, 109, 109);
		}
	}

	.form-control{
		box-shadow: none;
	}

	.has-success{
		.form-control{
			border-color: materialColor('green','darken-2');
			box-shadow: none;
		}
	}

	.has-error{
		.form-control{
			border-color: materialColor('red','darken-2');
			box-shadow: none;
		}
	}

	.has-warning{
		.form-control{
			border-color: materialColor('orange','darken-2');
			box-shadow: none;
		}
	}

	.btn-enviar{
		border-radius: 0;
		background: map-get($cores, cor-primaria );
		color: #fff;
		transition: background .4s  linear;
		font-family: 'montserratregular', sans-serif;
		&:hover{
			background: lighten(map-get($cores, cor-primaria ), 10%);
		}
	}
}
