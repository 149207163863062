.banner__carousel{
	.carousel{
		.carousel-indicators{
			align-items: flex-end;
			flex-direction: column;
			margin-bottom: 150px;

			li{
				border-radius: 15px;
				width: 15px;
				height: 15px;
				background-color: #fff;
				border: none;
				opacity: 1;
				transition: margin .3s linear;
				position: relative;
				margin-top: var(--margin, 0);
				margin-bottom: 10px;
				&::before {
					content:'';
					position: absolute;
					width: 2px;
					height: 60px;
					background: #FFF;
					bottom: 100%;
					left: 50%;
					transform-origin: center top;
					transition: all .3s linear;
					transform: translate(-1px, -10px) scale(var(--scale, 0), 1);
				}

				&.active{
					--margin: 70px;
					--scale: 1;
				}
			}
		}
	}
}

/*********************************************************************
						MOBILE/TABLET
***********************************************************************/
@include media-breakpoint-down(md){
	.banner__carousel{
		.carousel{
			.carousel-indicators{
				margin-bottom: 0;
				flex-direction: row;

				li{
					border-radius: 15px;
					width: 15px;
					height: 15px;
					background-color: #fff;
					border: none;
					opacity: 1;
					transition: margin .3s linear;
					position: relative;
					margin-right: var(--margin, 0);

					&::before {
						content:'';
						position: absolute;
						width: 60px;
						height: 2px;
						background: #FFF;
						left: 100%;
						top: 50%;
						transform-origin: left center;
						transition: all .3s linear;
						transform: translate(5px, -1px) scale(var(--scale, 0), 1);
					}

					&.active{
						--margin: 70px;
						--scale: 1;
					}
				}
			}
		}
	}
}
