@import "_mobile__controls";
@import "_main__topo";

.topo{
    position: relative;
    background: #000;
    left: 0;
    top: 0;
    z-index: 1000;
}

/***************************************************
					MOBILE/TABLET
****************************************************/
@include media-breakpoint-down(md){
	.topo{
		position: sticky;

	}
}
