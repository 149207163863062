@import 'card__noticia';

.secao__noticias{
	padding-top: 50px;
	padding-bottom: 50px;
	overflow: hidden;
	.container{
		min-width: 1920px;
		position: relative;
		display: grid;
		grid-template-columns: 50% 50%;

		.box__principal__noticias{
			grid-column: 1/2;
			margin-left: auto;
			margin-right: auto;

			.topo{
				display: flex;
				align-items: center;
				color: map-get($cores, font-color );
				text-transform: uppercase;

				h2{
					font-size: 15px;
					font-family: 'montserratmedium',sans-serif;
					letter-spacing: 5px;
				}

				img{
					margin-bottom: 10px;
					margin-right: 15px;
				}
			}

			.box__title{
				display: flex;
				flex-direction: column;

				.title{
					color: map-get($cores, cor-primaria );
					font-size: 30px;
					font-family: 'montserratextrabold',sans-serif;
					max-width: 637px;
				}

				.descricao{
					color: map-get($cores, font-color );
					font-size: 14px;
					font-family: 'montserratmedium',sans-serif;
					max-width: 728px;
				}
			}
		}
	}
}

@include media-breakpoint-down(lg){
	.secao__noticias{
		.container{
			.box__principal__noticias{
				margin-left: 0;
			}

			.img__secao__noticia{
				transform: translateX(-190px);
			}
		}
	}
}


/*********************************************************************
						MOBILE/TABLET
***********************************************************************/
@include media-breakpoint-down(md){
	.secao__noticias{
		.container{
			min-width: auto;
			display: flex;
			flex-direction: column;
			align-items: center;

			.box__principal__noticias{
				display: flex;
				flex-direction: column;

				.box__title{

					.descricao{
						margin-left: 0;
					}
				}
			}

			.img__secao__noticia{
				transform: translateX(0);
				margin-top: 20px;
			}
		}
	}
}
