.sub__rodape{
    padding-top: 30px;
    padding-bottom: 30px;
    .container{
        .descricao{
            color: #fff;
            text-align: center;
            font-size: 10px;
            font-family: 'montserratmedium',sans-serif;
            
            p{
                margin-bottom: 0;
            }
        }

        .gv8{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 10px;
            
            a{
                color: #fff;
                margin-left: 5px;
                margin-top: 2px;
            }
        }
    }
}