/*DESKTOP*/
@include media-breakpoint-up(lg){
    .main__topo{
        width: 100%;
        max-width: 1170px;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
		justify-content: space-between;

		.logo{
			padding: 15px 0;
		}

		.menu{
			display: flex;

			li{
				display: flex;
				padding:  15px;
				align-items: center;
			}

			a{
				display: flex;
				align-items: center;
				font-size: 15px;
				color: #fff;
				font-family: 'montserratsemibold',sans-serif;
				transition: color .3s linear;
				position: relative;
				padding: 0;
				margin: 0;
				height: 30px;

				&::after{
					content: "";
					width: 100%;
					height: 3px;
					background: #fff;
					position: absolute;
					bottom: 0;
					transition: transform .4s linear;
					transform: scale(0,1);
				}

				&:hover{
					text-decoration: none;
					font-family: 'montserratextrabold',sans-serif;

					&::after{
						transform: scale(1,1);
					}
				}
			}



			.active a {
				font-family: 'montserratextrabold',sans-serif;
				text-decoration: none;

				&::after{
					transform: scale(1,1);
				}
			}
		}

		.box__redes-sociais{
			display: flex;
			align-items: center;
			.icone__social{
				text-decoration: none;
				background: #a3a3a3;
				color: #fff;
				width: 25px;
				height: 25px;
				border-radius: 25px;
				font-size: 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 3px solid #a3a3a3;
				transition:  background .4s linear;
				margin-right: 5px;
				margin-bottom: 15px;

				&:hover{
					background: map-get($cores, cor-primaria );
				}
			}

		}
    }
}

@media(max-width:1040px){
	.main__topo{
		.menu{
			li{
				a{
					font-size: 13px;
				}
			}
		}
	}
}

/*MOBILE*/
@include media-breakpoint-down(md){
    .main__topo{
        position: fixed;
        left: 0;
        top: 0;
        width: 250px;
        height: 100%;
        background: #000;
        z-index: 999;
        overflow: auto;
        transform: translateX(-100%);

		.logo{
			padding: 5px 15px;
			text-align: center;
		}

		.menu{
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;

			a{
				display: block;
				padding: 10px 15px;
				font-size: 15px;
				color: #fff;
				font-family: 'montserratsemibold',sans-serif;
				transition: color .3s linear;
				&:hover{
					font-family: 'montserratbold',sans-serif;
					text-decoration: none;
					position: relative;

					&::after{
						content: '';
						width: 30px;
						height: 3px;
						background: #fff;
						position: absolute;
						bottom: 45%;
						margin-left: 15px;
					}
				}
			}

			.active a{
				color: map-get($cores, cor-primaria );
				font-family: 'montserratbold',sans-serif;
				text-decoration: none;
				position: relative;

				&::after{
					content: '';
					width: 30px;
					height: 3px;
					background: map-get($cores, cor-primaria );
					position: absolute;
					bottom: 45%;
					margin-left: 15px;
				}
			}
		}

		.box__redes-sociais{
			display: flex;
			justify-content: center;
			margin-top: 30px;

			.icone__social{
				text-decoration: none;
				background: #a3a3a3;
				color: #fff;
				width: 30px;
				height: 30px;
				border-radius: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 2px solid #a3a3a3;
				transition:  background .4s linear;
				margin-right: 10px;

				&:hover{
					background: map-get($cores, cor-primaria );
				}
			}

		}
    }

    .main__topo.main__topo--shown{
        animation: showMenu .4s linear forwards;
    }

    .main__topo.main__topo--hide{
        animation: hideMenu .4s linear forwards;
    }

    .backdrop{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.8);
        backdrop-filter: blur(4px);
        display: none;
    }

    .backdrop.main__topo--shown{
        display: block;
        animation: showBackdrop .4s linear forwards;
    }

    .backdrop.main__topo--hide{
        display: block;
        animation: hideBackdrop .4s linear forwards;
    }
}

@keyframes showBackdrop {
    from {
        opacity: 0;
    }

    to{
        opacity: 1;
    }
}

@keyframes hideBackdrop {
    to {
        opacity: 0;
    }

    from{
        opacity: 1;
    }
}

@keyframes showMenu {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes hideMenu {
    to {
        transform: translateX(-100%);
        opacity: 0;
    }

    from{
        transform: translateX(0);
        opacity: 1;
    }
}
