@import "_box__institucional";
@import "_box__contato";
@import "_newsletter";
@import "_sub__rodape";

.rodape{
    background: map-get($cores, cor-primaria );

    .main__topo{
        padding-top: 30px;
        padding-bottom: 40px;
        
        .box__logo_e_descricao{

            .descricao{
                color: #959595;
                font-size: 14px;
                font-family: 'montserratmedium',sans-serif;
                margin-top: 10px;
            }
            
            .box__redes-sociais{
                .icone__social{
                    background: #959595;
                    color: map-get($cores, cor-primaria );
                    transition: background .4s linear;
                    &:hover{
                        background: #fff;
                    }
                }
            }
        }
    }
}