.secao__sobre-nos{
	width: 100%;
	overflow: hidden;


	.container{
		min-width: 1920px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		display: grid;
		grid-template-columns: 43% 67%;
		grid-template-rows: 452px auto;
		z-index: 2;
		min-height: 600px;

		&::after{
			content:'';
			background: #202020;
			display: block;
			grid-column:1/3;
			grid-row: 2/3;
			position: relative;
			z-index: -1;


		}

		img{
			grid-column: 1/2;
			grid-row: 1/3;
		}

		.box__descricao{
			grid-column: 2/3;
			grid-row: 1/2;
			max-width: 600px;
			padding-top: 57px;

			.topo{
				display: flex;
				align-items: center;
				color: map-get($cores, font-color );
				text-transform: uppercase;

				h2{
					font-size: 15px;
					font-family: 'montserratmedium',sans-serif;
					letter-spacing: 5px;
				}

				img{
					margin-bottom: 10px;
					margin-right: 15px;
				}
			}

			.title{
				font-size: 30px;
				color: map-get($cores, cor-primaria );
				font-family: 'montserratextrabold', sans-serif;
				max-width: 713px;
			}

			.descricao{
				color: map-get($cores, font-color );
				font-size: 14px;
				font-family: 'montserratmedium', sans-serif;
				line-height: 18px;
			}
		}

		.line{
			grid-column: 2/3;
			grid-row: 2/3;
			display: block;
			background: #fff;
			width: 600px;
			height: 2px;
			margin-top: 55px;
		}
	}

}

@include media-breakpoint-down(md){
	.secao__sobre-nos{
		padding-bottom: 30px;
		.container{
			display: flex;
			flex-direction: column-reverse;
			min-width: auto;
			transform: translateX(0);
			left: 0;
		}
	}
}
