.empresa#modelo4{
    padding-bottom: 60px;

    .content__editable{
        @include clearfix;
        img{
            max-width: 100%;
            height: auto !important;
            border-top-left-radius: 30px;
        }

        p{
            color: #232323;
            font-size: 15px;
            text-align: justify;
        }
    }

    .btn-voltar{
        margin-top: 40px;
        min-width: 200px;
        background-color: map-get($cores, cor-primaria );
        color: #fff;
        font-family: 'montserratmedium',sans-serif;
        transition: background .4s linear;

        &:hover{
            background:  lighten(map-get($cores, cor-primaria ), 10%);
        }
    }
}

@include media-breakpoint-down(md){
	.empresa#modelo4{
		.content__editable{
			img{
				margin: 0 !important;
			}
		}

	}
}
