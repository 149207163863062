.secao__contato{
	.container{
		border-top: 1px solid #dbdbdb;
		padding-top: 50px;
		padding-bottom: 60px;
		.topo{
			display: flex;
			align-items: center;
			justify-content: center;
			color: map-get($cores, font-color );
			text-transform: uppercase;

			h2{
				font-size: 15px;
				font-family: 'montserratmedium',sans-serif;
				letter-spacing: 5px;
			}

			img{
				margin-bottom: 10px;
				margin-right: 15px;
			}
		}

		.title{
			color: map-get($cores, cor-primaria );
			font-size: 30px;
			font-family: 'montserratextrabold',sans-serif;
			margin-bottom: 15px;
			text-align: center;
		}

		.form__contato{
			display: flex;
			flex-direction: column;

			.form-control{
				border: none;
				border-radius: 0;
				box-shadow: none;
				outline: none;
				height: 50px;
				background: #ededed;
				padding-left: 20px;
				font-size: 13px;
				color: map-get($cores, cor-primaria );

				&::placeholder{
					color: map-get($cores, cor-primaria );
					font-size: 13px;
					font-family: 'montserratlight_italic';
				}
			}

			textarea{
				height: 150px !important;
			}
			.btn__enviar{
				width: 272px;
				height: 50px;
				background: map-get($cores, cor-primaria );
				color: #fff;
				font-family: 'montserratmedium',sans-serif;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: background .4s linear;
				align-self: center;
				margin-top: 30px;

				&:hover{
					background: lighten(map-get($cores, cor-primaria ), 10%);
				}
			}
		}
	}
}
