.card__servico{
	width: 350px;
	padding-bottom: 32px;
	padding-top: 40px;
	padding: 32px 30px 40px 30px;
	background: transparent;
	transition: background .4s linear;

	&:hover{
		background: #292929;
		a{
			text-decoration: none;
		}

		.topo__card{
			.icone{
				background: #202020;
			}
		}
	}

	.topo__card{
		display: flex;
		align-items: center;
		width: 100%;
		.icone{
			width: 45px;
			height: 45px;
			border-radius: 45px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #292929;
			transition: background .4s linear;

			img{
				transform: translateX(20px);
			}
		}

		h2{
			color: #fff;
			font-size: 18px;
			font-family: 'montserratextrabold',sans-serif;
			margin-left: 40px;
			max-width: 200px;
		}
	}

	.content__card{
		color: map-get($cores, font-color );
		text-align: justify;
		font-size: 14px;
		font-family: 'montserratmedium',sans-serif;
		margin-top: 15px;
	}
}


/*********************************************************************
						MOBILE/TABLET
***********************************************************************/
@include media-breakpoint-down(md){
	.card__servico{
		max-width: 280px;
	}
}
