@import 'carousel__servicos';
@import 'card__servico';

.secao__servicos{
	background: #202020;
	.container{
		.topo{
			display: flex;
			align-items: center;
			color: map-get($cores, font-color );
			text-transform: uppercase;
			background: #202020;

			h2{
				font-size: 15px;
				font-family: 'montserratmedium',sans-serif;
				letter-spacing: 5px;
			}

			img{
				margin-bottom: 10px;
				margin-right: 15px;
			}
		}

		.box__title{
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title{
				color: #fff;
				font-size: 30px;
				font-family: 'montserratextrabold',sans-serif;
				max-width: 615px;
			}

			.descricao{
				color: map-get($cores, font-color );
				font-size: 14px;
				font-family: 'montserratmedium',sans-serif;
				max-width: 440px;
			}
		}
	}

	.agendar{
		width: 100%;
		background: #fff;

		.container{
			border-bottom: 2px solid #dbdbdb;
			display: flex;
			justify-content: space-between;
			min-height: 150px;
			align-items: center;

			h2{
				color: map-get($cores, cor-primaria );
				font-size: 24px;
				font-family: 'montserratextrabold',sans-serif;
			}

			.btn__agendar{
				background: map-get($cores, cor-primaria );
				height: 50px;
				width: 275px;
				color: #fff;
				border-radius: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: 'montserratmedium', sans-serif;
			}
		}
	}
}

@include media-breakpoint-down(lg){
	.container{
		.box__title{
			.descricao{
				margin-left: 20px;
			}
		}
	}
}

@include media-breakpoint-down(md){
	.secao__servicos{
		padding-top: 30px;

		.container{
			display: flex;
			flex-direction: column;
			align-items: center;

			.topo{
				z-index: -1;
			}
			.box__title{
				display: flex;
				flex-direction: column;
				.title{
					font-size: 23px;
				}

				.descricao{
					text-align: left;
					margin-left: 0;
				}
			}

		}

		.agendar{
			padding-top: 20px;
			.btn__agendar{
				margin-bottom: 20px;
			}
		}
	}
}
