.newsletter{
    .title{
        color: #fff;
        text-transform: uppercase;
        font-family: 'montserratextrabold',sans-serif;
        font-size: 14px;
        margin-bottom: 35px;
        margin-top: 60px;
    }

    .descricao{
        color: #959595;
        font-size: 13px;
        font-family: 'montserratmedium',sans-serif;
    }

    .form__rodape{
        .form-control{
            border-radius: 0;
            height: 44px;
            font-size: 14px;
            font-family: 'montserratregular',sans-serif;
            box-shadow: none;
            border: none;

        }

        .btn__assinar{
            color: #fff;
            font-size: 14px;
            font-family: 'montserratextrabold',sans-serif;
            text-transform: uppercase;
            width: 100%;
            text-align: left;
            position: relative;
            padding-left: 0;
            transition: color .4s linear;

            &:hover{
                color: #959595;

                &::after{
                    background: #959595;
                }
            }

            &::after{
                content: '';
                width: 177px;
                height: 2px;
                background: #fff;
                position: absolute;
                bottom: 50%;
                margin-left: 10px;
                transition: background .4s linear;
            }
        }
    }
}

@include media-breakpoint-down(lg){
	.newsletter{
		.form__rodape{
			.btn__assinar{
				&::after{
					width: 130px;
				}
			}
		}
	}
}
