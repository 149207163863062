.secao__servicos{
	.owl-carousel{
		margin-top: 20px;
		.owl-item{
			img{
				width: auto;
			}
		}

		.owl-nav{
			width: 100%;
			display: flex;
			justify-content: center;

			.owl-next{
				background: transparent;
				border: none;
				color: map-get($cores, font-color );
				font-size: 60px;
				transition: color .4s linear;

				&:hover{
					color: #fff;
				}
			}

			.owl-prev{
				background: transparent;
				border: none;
				color: map-get($cores, font-color );
				font-size: 60px;
				transition: color .4s linear;

				&:hover{
					color: #fff;
				}
			}
		}
	}
}


@include media-breakpoint-down(md){
	.secao__servicos{
		.owl-carousel{
			.owl-item{
				display: flex;
				justify-content: center;
			}
		}
	}
}
