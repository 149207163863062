.box__institucional{
    .title{
        color: #fff;
        text-transform: uppercase;
        font-family: 'montserratextrabold',sans-serif;
        font-size: 14px;
        margin-bottom: 35px;
        margin-top: 60px;
    }

    .menu__rodape{
        list-style: none;
        padding: 0;
        display: flex;

        li{
            display: flex;
            margin-bottom: 25px;

            a{
                color: #959595;
                text-decoration: none;
                text-transform: uppercase;
                font-family: 'montserratbold',sans-serif;
                font-size: 14px;
                transition: color .4s linear;

                &:hover{
                    color: #fff;
                }
            }
        }

       .box__part2{
           margin-left: 30px;
       }
    }
}